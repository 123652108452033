// theme.js

const theme = {
  color: {
    // primary: '#144773',
    primary: '#001C36',
    secondary: '#2ecc71',
    background: '#ecf0f1',
    textPrimary: '#444444',
    textSecondary: '#666666',
    babyBlue: '#96CAF2',
    accent: '#F3C055',
    light: '#FFFFFF',
    textDark: '#000000',
    brick: '#BE5621',
    brown: '#5B3013',
    highlight: '#FFE0C4',
    beige: '#F2E8DD',
    cream: '#FBF9F7',
    // Add more colors as needed
  },
  fontFamily: {
    primary: 'Koulen',
    secondary: 'League Spartan',
    tertiary: 'Inter',
    // Add more fonts as needed
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
    // Add more spacing values as needed
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    // Add more border radius values as needed
  },
  // Add any other theme values you need
};

export default theme;
