import HeroSection from '../../sections/HeroSection';
// import HighlightedProducts from '../../sections/HighlightedProducts';
import Navbar from '../../components/Navbar'
import ContactSection from '../../sections/Contact'
// import AboutUs from '../../sections/AboutUs';
import Reviews from '../../sections/Reviews';
import HeroVideo from '../../sections/HeroVideo';
// import CategoriesSection from '../../sections/CategoriesSection';

function Home() {
  return (
    <>
      <Navbar position='fixed' />
      {/* <HeroSection /> */}
      <HeroVideo/>
      {/* <HighlightedProducts /> */}
      {/* <CategoriesSection /> */}
      {/* <Reviews /> */}
      {/* <AboutUs /> */}
      <ContactSection />
    </>
  );
}

export default Home;
