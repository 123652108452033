import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Wordmark from '../../assets/digital-porters-wordmark.svg';

function HeroVideo() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight); // Update height on window resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppContainer style={{ height: viewportHeight }}>
      <BackgroundVideo playsInline autoPlay muted loop>
        <source
          src={'https://d103hi3q9ef01x.cloudfront.net/everest-optimized.mp4'}
          type='video/mp4'
        />
        Your browser does not support the video tag.
      </BackgroundVideo>
      <Content>
        <StyledWordmark src={Wordmark} alt={'Digital Porters'} />
      </Content>
      <Footer>
        <FooterLink href='mailto:hello@digitalporters.com'>
          hello@digitalporters.com
        </FooterLink>
      </Footer>
    </AppContainer>
  );
}

// Styled components
const AppContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
}));

const BackgroundVideo = styled('video')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensures the video covers the screen without distortion
  zIndex: -1,
}));

const Content = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontSize: '3rem',
  textAlign: 'center',
  fontFamily: "'Silkscreen', sans-serif",
  fontWeight: 700,
}));

const StyledWordmark = styled('img')(() => ({
  maxWidth: '1000px',
  width: '100%',
  '@media (max-width: 768px)': {
    maxWidth: '90%', // Adjust for smaller screens
  },
}));

const Footer = styled('div')(() => ({
  position: 'absolute',
  bottom: '20px', // Positions it 20px above the bottom of the screen
  width: '100%',
  textAlign: 'center',
}));

const FooterLink = styled('a')(() => ({
  fontSize: '1rem',
  fontWeight: 700,
  color: 'white',
  textDecoration: 'none', // No underline by default
  transition: 'color 0.3s ease, textDecoration 0.3s ease', // Smooth transition for hover effect

  '&:hover': {
    textDecoration: 'underline', // Underline on hover
    color: '#cccccc', // Optional: Change color on hover
  },

  '@media (max-width: 768px)': {
    fontSize: '0.8rem', // Smaller font size for mobile
  },

  '@media (max-width: 480px)': {
    fontSize: '0.7rem', // Even smaller font size for very small screens
  },
}));

export default HeroVideo;
