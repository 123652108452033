import React from 'react'
import { styled } from '@mui/material';
import theme from '../../styles';
import ArcimexLogotype from '../../assets/digital-porters-logotype-white.svg'
import Hyperlink from '../../components/Hyperlink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = ({ children }) => {

  return (
    <MainContainer>
      <Hyperlink destination='/'>
        <Logo src={ArcimexLogotype} alt='Arcimex Logotype' />
      </Hyperlink>
      <ItemsContainer>
        {/* <MenuItem href='/privacy-notice' >
          Privacy notice
        </MenuItem>
        <MenuItem href='/terms-and-conditions' >
          Terms and conditions
        </MenuItem> */}
        <a href='https://www.instagram.com/digitalporters/' target='_blank' rel='noopener noreferrer' className='hover'>
          <FontAwesomeIcon
            icon={faInstagram}
            color={theme.color.light}
            style={{
            }}
            size='xl'
          />
        </a>
        <a href='https://w.app/digitalporters' target='_blank' rel='noopener noreferrer' className='hover'>
          <FontAwesomeIcon
            icon={faWhatsapp}
            color={theme.color.light}
            style={{
            }}
            size='xl'
          />
        </a>
      </ItemsContainer>
    </MainContainer >
  )
}

const MainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.color.primary,
  padding: '20px 100px',
  width: '100%',
  boxSizing: 'border-box',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    justifyContent: 'center',
  },

}));
const Logo = styled('img')(() => ({
  width: '150px',
  transition: 'transform 0.3s ease-out',
  // '&:hover': {
  //   transform: 'translateY(-10px)',
  // },
}));
const ItemsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  padding: '20px',
}));
const MenuItem = styled('a')(() => ({
  fontFamily: theme.fontFamily.primary,
  color: theme.color.light,
  textDecoration: 'none',
  transition: 'transform 0.3s ease-out',
  padding: 20,
  '&:hover': {
    transform: 'translateY(-10px)',
  },
}));

export default Footer
