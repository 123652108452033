import { styled } from '@mui/material';
import theme from '../../styles';
import { useNavigate } from 'react-router-dom';

const PrimaryButton = (props) => {
  const navigate = useNavigate();

  const Button = styled('button')(() => ({
    width: props.width || 'auto',
    padding: props.padding || '20px 50px',
    borderRadius: props.borderRadius || 50,
    border: props.border || 'none',
    color: props.color || theme.color.light,
    backgroundColor: props.backgroundColor || theme.color.brick,
    fontSize: props.fontSize || '20px',
    fontWeight: '900',
    fontFamily: theme.fontFamily.primary,
    transition: 'transform 0.3s ease-out',
    '&:hover': {
      transform: 'translateY(-10px)',
    },
  }));

  const handleNavigate = (destination) => {
    navigate(destination);
  };

  return (
    <Button
      type={props.type || null}
      onClick={
        props.onClick
          ? props.onClick
          : props.destination
            ? () => handleNavigate(props.destination)
            : null
      }
    >
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
