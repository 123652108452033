import React, { useState, useEffect, forwardRef } from 'react';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import { useScroll } from '../../components/ScrollContext'
import Swal from 'sweetalert2';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { styled } from '@mui/material';
import theme from '../../styles';
import CustomButton from '../../components/CustomButton';
import PrimaryButton from '../../components/PrimaryButton';
import Test from '../../assets/test.svg'
import WavePattern from '../../assets/wave-pattern.svg'



const ContactSection = forwardRef((props, ref) => {
  const { contact } = useScroll();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [recaptcha, setRecaptcha] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600); // Change the threshold based on your desired breakpoint
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    console.log('sending')
    e.preventDefault();

    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAIL_USER_ID;

    if (!recaptcha) {
      console.log('Recaptcha failed');
      return;
    }

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      from_phone_number: formData.phoneNumber, // Adjust to appropriate field if it's a phone number
      message: formData.message,
      to_email: 'hello@digitalporters.com',
      to_company: 'Digital Porters',
      to_website: 'digitalporters.com',
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          category: '',
          name: '',
          email: '',
          phoneNumber: '',
          message: '',
        });
        Swal.fire('Success!', "Email sent, the porters will get in contact with you", 'success');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  if (!reCaptchaKey) {
    console.error("reCaptcha key is not defined");
    return null; // or render some fallback UI
  }

  const inputStyle = {
    width: '80%',
    backgroundColor: 'white',
    margin: 10,
    borderRadius: '10px',
  };

  return (
    <MainContainer onSubmit={handleSubmit} ref={contact}>

      <TextInputContainer>
        <H1>Contact us</H1>
        <H2> Get in touch with the porters by filling out this form</H2>
        <CustomTextField
          label='Name'
          variant='filled'
          type='text'
          id='name'
          name='name'
          value={formData.name}
          onChange={handleChange}
          required
          style={inputStyle}
        />
        <CustomTextField
          label='Email'
          variant='filled'
          type='email'
          id='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
          style={inputStyle}
        />
        <CustomTextField
          label='Phone'
          variant='filled'
          id='phoneNumber'
          name='phoneNumber'
          value={formData.phoneNumber}
          onChange={handleChange}
          required
          style={inputStyle}
        />
        <CustomTextField
          label='Message'
          variant='filled'
          id='message'
          name='message'
          value={formData.message}
          onChange={handleChange}
          required
          multiline
          maxRows={4}
          style={inputStyle}
        />
        <ButtonContainer>
          <PrimaryButton
            variant='contained'
            type='submit'
            width='100%'
            marginTop='10px'
            border={`2px solid ${theme.color.primary}`}
            borderRadius='10px'
            backgroundColor={theme.color.primary}
            color={theme.color.light}
          >
            Send
          </PrimaryButton>
        </ButtonContainer>
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <GoogleReCaptcha onVerify={() => setRecaptcha(true)} />
        </GoogleReCaptchaProvider>
      </TextInputContainer>
      <div style={{ position: 'absolute', bottom: '-10px', left: 0, right: 0, zIndex: 1 }}>
        <svg viewBox="0 0 1440 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 354L240 482L480 450L720 322L960 418L1200 386L1440 418V800.5H1200H960H720H480H240H0V354Z" fill="#001C36" />
        </svg>
      </div>
    </MainContainer>
  );
});

const MainContainer = styled('form')(() => ({
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.color.light,
  // margin: 100,
  padding: '100px 50px',
  backgroundSize: 'cover',
  backgroundImage: `url(${WavePattern})`,

  gap: 30,
  '@media (max-width: 600px)': {
    padding: '40px',
  },
}));

const TextInputContainer = styled('div')({
  width: '50%',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 40,
  borderRadius: 20,
  zIndex: 2,
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
  backgroundColor: theme.color.light,
  '@media (max-width: 600px)': {
    padding: '20px',
    width: '100%',
  },
});

const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.primary,
  color: theme.color.textSecondary,
  fontSize: '50px',
  fontWeight: '500',
  lineHeight: '50px',
  margin: 0,
  textAlign: 'center',
}));

const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.primary,
  color: theme.color.textSecondary,
  fontSize: '20px',
  fontWeight: '500',
  textAlign: 'center',
}));

const ButtonContainer = styled('div')(() => ({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '10px 60px',
  '@media (max-width: 600px)': {
    padding: '20px',
  },
}));

const CustomTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    width: '100%',
    backgroundColor: theme.color.light,
    transition: 'box-shadow 0.3s ease-in-out',
    border: `2px solid #666666`,
    borderRadius: '10px',
  },
  '& .MuiFilledInput-root:focus-within, & .MuiFilledInput-root.Mui-focused': {},
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    border: `none`,
  },
  '& .MuiFilledInput-underline:hover:before': {
    border: 'none !important',
  },
  '& .MuiInputLabel-root': {
    fontWeight: 'bold',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.color.textPrimary,
  },
  '& .MuiInputLabel-asterisk': {
    display: 'none',
  },
});

export default ContactSection;
